import {Router} from '@reach/router';
import moment from 'moment';
import React, {useContext, useState, useEffect} from 'react';
import {Day} from 'src/components/entries/day';
import {ContentWrapper} from 'src/components/layout/contentWrapper';
import {
  ContextBar,
  ContextItem,
  ContextLink,
} from 'src/components/layout/contextBar';
import Layout from 'src/components/layout/layout';
import {ProjectSummary} from 'src/components/projects/projectSummary';
import {ProjectSummaryLink} from 'src/components/projects/projectSummaryLink';
import SEO from 'src/components/seo';
import {Card} from 'src/components/ui/card';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from 'src/context/globalContextProvider';
import {api} from 'src/shared/api';
import {daysFromEntries} from 'src/shared/utilities/entryHelper';
import styles from './index.module.scss';

export default () => (
  <Router basepath="/projects">
    <ProjectDetailsPage path="/:projectId">hello world</ProjectDetailsPage>
    <ProjectListPage path="/">hello world</ProjectListPage>
  </Router>
);

const ProjectDetailsPage = (props) => {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const [days, setDays] = useState({});
  const [projectLoading, setProjectLoading] = useState(true);
  const [entriesLoading, setEntriesLoading] = useState(true);
  const [project, setProject] = useState(state.projectMap[props.projectId]);
  const loading = projectLoading || entriesLoading;

  useEffect(() => {
    setProject(state.projectMap[props.projectId]);
  }, [props.projectId, state.projectMap]);

  useEffect(() => {
    // Clear project detail state if its changed
    if (state.projectDetailId !== props.projectId) {
      dispatch({type: 'CLEAR_PROJECT_DETAILS'});
    }

    if (project) {
      setProjectLoading(false);
    } else {
      api().get(`/api/projects/${props.projectId}/`).then((response) => {
        dispatch({type: 'ADD_PROJECT', project: response.data});
        setProjectLoading(false);
      }).catch((error) => {
        console.log(error);
        setProjectLoading(false);
      });
    }

    api().get(`/api/entries/?project=${props.projectId}`).then((response) => {
      dispatch({
        type: 'SET_PROJECT_DETAIL_ENTRIES',
        projectId: props.projectId,
        entries: response.data.entries,
      });
      setEntriesLoading(false);
    }).catch((error) => {
      console.log(error);
      setEntriesLoading(false);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(state.projectMap).length > 0) {
      setDays(daysFromEntries(state.projectDetailEntries, state.projectMap));
    }
  }, [state.projectDetailEntries, state.projectMap]);

  return (
    <Layout>
      <SEO title={project ? project.name : 'Loading'} />
      <ContextBar>
        <ContextLink
          divider
          to="/projects"
          text="Projects"
        />
        {project &&
          <ContextItem
            text={project.name}
          />
        }
      </ContextBar>
      <ContentWrapper>
        {loading &&
          <div>
            <Card skeleton />
            <Card skeleton />
            <Card skeleton />
          </div>
        }
        {!loading && project &&
          <ProjectSummary
            pk={project.pk}
            name={project.name}
            uncategorized={project.uncategorized}
          />
        }
        {!loading && days &&
          Object.keys(days).sort((a, b) => moment(b) - moment(a)).map((key) => (
            <Day
              projectView
              key={`day-${key}`}
              date={key}
              data={days[key]}
              // onEntryDeleted={handleEntryDeleted}
            />
          ))
        }
      </ContentWrapper>
    </Layout>
  );
};

const ProjectListPage = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // TODO: Implement logic to not re-fetch data if this is a "warm load"
    api().get('/api/projects/').then((response) => {
      dispatch({type: 'SET_PROJECTS', projects: response.data});
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }, []);

  return (
    <Layout>
      <SEO title='Projects' />
      <ContextBar>
        <ContextItem
          text="Projects"
        />
      </ContextBar>
      <ContentWrapper>
        {loading &&
          <div>
            <Card skeleton />
            <Card skeleton />
            <Card skeleton />
          </div>
        }
        {!loading && state.projects.map((project) => (
          <div key={`project-${project.pk}`} className={styles.projectSummary}>
            <ProjectSummaryLink
              pk={project.pk}
              name={project.name}
              uncategorized={project.uncategorized}
            >
              {JSON.stringify(project)}
            </ProjectSummaryLink>
          </div>
        ))}
      </ContentWrapper>
    </Layout>
  );
};
