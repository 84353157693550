import {Link} from 'gatsby';
import React, {useState} from 'react';
import {IoIosCheckmark} from 'react-icons/io';
import {IoIosArrowForward} from 'react-icons/io';
import {CardElement, injectStripe} from 'react-stripe-elements';
import {Button} from 'src/components/ui/button';
import {Card} from 'src/components/ui/card';
import {TextInput} from 'src/components/ui/textInput';
import LogoWithText from 'src/images/logo_light_with_text.svg';
import StripeBadge from 'src/images/powered_by_stripe.svg';
import {api} from 'src/shared/api';
import formStyles from 'src/shared/styles/forms.module.scss';
import {setSessionData} from 'src/shared/utilities/session';
import styles from './projectSummaryLink.module.scss';

export const ProjectSummaryLink = (props) => {
  return (
    <Link to={`/projects/${props.pk}`} className={styles.link}>
      <Card>
        <div className={styles.contentWrapper}>
          <div className={styles.title}>
            <div className={styles.name}>
              {props.name}
            </div>
            <div>
              <IoIosArrowForward />
            </div>
          </div>
        </div>
      </Card>
    </Link>
  );
};
