import React, {useContext, useState} from 'react';
import {ProjectStatus} from 'src/components/projects/project-status';
import {Button} from 'src/components/ui/button';
import {Card} from 'src/components/ui/card';
import {TextInput} from 'src/components/ui/textInput';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from 'src/context/globalContextProvider';
import {api} from 'src/shared/api';
import formStyles from 'src/shared/styles/forms.module.scss';
import validators from 'src/shared/utilities/validators';
import styles from './projectSummary.module.scss';

export const ProjectSummary= (props) => {
  const [name, setName] = useState(props.name);
  const dispatch = useContext(GlobalDispatchContext);
  const [nameError, setNameError] = useState(null);
  const [editing, setEditing] = useState(false);

  const resetValidation = () => {
    setNameError(null);
  };

  const validateForm = () => {
    resetValidation();
    let valid = true;

    const nameErrorMessage = validators.validateProjectName(name);
    if (nameErrorMessage) {
      setNameError(nameErrorMessage);
      valid = false;
    }

    return valid;
  };
  const handleEditClick = () => {
    setEditing(true);
  };

  const handleCancelClick = () => {
    resetValidation();
    setName(props.name);
    setEditing(false);
  };

  const handleSaveClick = () => {
    if (validateForm()) {
      api().patch(`/api/projects/${props.pk}/`, {
        name: name,
      }).then((response) => {
        dispatch({type: 'UPDATE_PROJECT', project: response.data});
        setEditing(false);
      }).catch((error) => {
        console.log(error);
      });
    }
  };

  return (
    <Card>
      {!editing &&
        <>
          <div className={styles.header}>
            <div className={styles.title}>
              <div className={styles.name}>
                {props.name}
              </div>
              {!props.uncategorized &&
                <div className={styles.actions}>
                  <Button onClick={handleEditClick} small>Edit</Button>
                </div>
              }
            </div>
            {!props.uncategorized &&
              <div className={styles.status}>
                <ProjectStatus />
              </div>
            }
          </div>
          <div className={styles.contentWrapper}>
            {props.uncategorized &&
              <div>
                This is a special project where uncategorized entries
                are placed by default. It can't be edited (for now).
              </div>
            }
          </div>
        </>
      }
      {editing &&
        <div className={styles.editWrapper}>
          <div className={styles.title}>Edit project</div>
          <form>
            <div className={formStyles.inputGroup}>
              <label htmlFor="name">Name</label>
              <TextInput
                block
                name="name"
                value={name}
                errorMessage={nameError}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </form>
          <div className={styles.actions}>
            <Button onClick={handleCancelClick} small>Cancel</Button>
            <Button primary onClick={handleSaveClick} small>Save</Button>
          </div>
        </div>
      }
    </Card>
  );
};
