import React, {useContext, useState} from 'react';
import {Button} from 'src/components/ui/button';
import {Card} from 'src/components/ui/card';
import {TextInput} from 'src/components/ui/textInput';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from 'src/context/globalContextProvider';
import {api} from 'src/shared/api';
import formStyles from 'src/shared/styles/forms.module.scss';
import styles from './project-status.module.scss';

export const ProjectStatus = (props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.indicator}>
      </div>
      <div className={styles.label}>
        Online
      </div>
    </div>
  );
};
